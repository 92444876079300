import axios from './Axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import {
//     mapMutations,
//   } from 'vuex'

const Base = {
    data() {
        return {
            isAuthenticated: localStorage.getItem('token_auth'),
            akses: {},
            pesan: "" 
        }
    },
    mounted() {

    },
    components:{
        ToastificationContent
    },
    computed: {

    },
    methods: {    
        notification(variant , title, text ){
            this.$toast({
                component: ToastificationContent,
                props: {
                  title: title,
                  text: text   ,
                  icon: 'EditIcon',
                  variant: variant,
                },
              }); 
        },
        async cek_akses() { 
            const self = this;
            await axios({
                    method: 'PUT',
                    url: '/api/aut/cek',
                    data: {
                        url: window.location.hash
                    },
                    headers: {
                        'Authorization': self.isAuthenticated
                    }
                })
                .then(function (response) {
                    const st = response.data.result.st;
                    if (st == '0') {
                        return self.$router.push({
                            name: 'no-akses'
                        });
                    }
                    if (st == '00') {
                        return self.$router.push({
                            name: 'no-token'
                        });
                    }
                    if (st == '1') {
                        self.akses = response.data.result.rs
                        if (response.data.result.rs.aksesr == '0') {
                            return self.$router.push({
                                name: 'no-akses'
                            });
                        }
                    }
                }).catch(err => {
                    self.pesan = err.message;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Maaf...',
                          text:self.pesan  ,
                          icon: 'EditIcon',
                          variant: 'warning',
                        },
                      }); 
                });

        },
        async cek_akses_cs(url) {
            const self = this;
            await axios({
                    method: 'PUT',
                    url: '/api/aut/cek',
                    data: {
                        url: url
                    },
                    headers: {
                        'Authorization': self.isAuthenticated
                    }
                })
                .then(function (response) {
                    const st = response.data.result.st;
                    if (st == '0') {
                        return self.$router.push({
                            name: 'no-akses'
                        });
                    }
                    if (st == '00') {
                        return self.$router.push({
                            name: 'no-token'
                        });
                    }
                    if (st == '1') {
                        self.akses = response.data.result.rs
                        if (response.data.result.rs.aksesr == '0') {
                            return self.$router.push({
                                name: 'no-akses'
                            });
                        }
                    }
                }).catch(err => {
                    self.pesan = err.message;
                     
                this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Maaf...',
                      text:self.pesan  ,
                      icon: 'EditIcon',
                      variant: 'warning',
                    },
                  }); 
                });

        },
        async cek_token() {
            const self = this;
            await axios({
                    method: 'PATCH',
                    url: '/api/aut/cek_token',
                    data: self.loginData,
                    headers: {
                        'Authorization': self.isAuthenticated
                    }
                })
                .then(function (response) {
                    const st = response.data.result.st;
                    if (st == '0') {
                        return self.$router.push({
                            name: 'no-token'
                        });

                    }
                    if (st == '00') {
                        return self.$router.push({
                            name: 'no-akses'
                        });

                    }
                }).catch(err => {
                    self.pesan = err.message;
                    return self.$router.push({
                        name: 'no-token'
                    });
                });

        }, 
        formatPrice(value) {
            let val = (value / 1).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        formatRp(value) {
            let val = (value / 1).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        Rp(value) {
            let val = (value / 1).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
    },

    cekNotifikasi(){ } 
}


export default Base;